export const vehicleTypeData = [
  { label: "Tata Ace", value: "Tata Ace" },
  { label: "Bolero Pick Up", value: "Bolero Pick Up" },
  { label: "Truck 10FT/407", value: "Truck 10FT/407" },
  { label: "Truck 14FT/709", value: "Truck 14FT/709" },
  { label: "Truck 17FT/909", value: "Truck 17FT/909" },
  { label: "Truck 18FT", value: "Truck 18FT" },
  { label: "Truck 19FT/1109", value: "Truck 19FT/1109" },
  { label: "Truck 20FT/1109", value: "Truck 20FT/1109" },
  { label: "Truck 22FT", value: "Truck 22FT" },
  { label: "Truck 24FT", value: "Truck 24FT" },
  { label: "Truck 32FT SXL", value: "Truck 32FT SXL" },
  { label: "Truck 32FT MXL", value: "Truck 32FT MXL" },
  { label: "Torus", value: "Torus" },
  { label: "Trailer 20FT", value: "Trailer 20FT" },
  { label: "Trailer 40FT", value: "Trailer 40FT" },
];

// Vehicle types and its body size
export const sizeOptions = {
  tataace: [
    { label: "0.6MT", value: "0.6MT" },
    { label: "0.7MT", value: "0.7MT" },
    { label: "0.75MT", value: "0.75MT" },
    { label: "1MT", value: "1MT" },
  ],
  boleropickup: [
    { label: "1MT", value: "1MT" },
    { label: "1.2MT", value: "1.2MT" },
    { label: "1.5MT", value: "1.25MT" },
    { label: "1.7MT", value: "1.7MT" },
    { label: "2MT", value: "2MT" },
  ],
  truck10ft_407: [
    { label: "2MT", value: "2MT" },
    { label: "3MT", value: "3MT" },
  ],
  truck14ft_709: [
    { label: "2MT", value: "2MT" },
    { label: "3MT", value: "3MT" },
    { label: "4MT", value: "4MT" },
  ],
  truck17ft_909: [
    { label: "3MT", value: "3MT" },
    { label: "4MT", value: "4MT" },
  ],
  truck18ft: [
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
    { label: "10MT", value: "10MT" },
  ],
  truck19ft_1109: [
    { label: "5MT", value: "5MT" },
    { label: "6MT", value: "6MT" },
    { label: "7MT", value: "7MT" },
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
  ],
  truck20ft_1109: [
    { label: "5MT", value: "5MT" },
    { label: "6MT", value: "6MT" },
    { label: "7MT", value: "7MT" },
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
  ],
  truck22ft: [
    { label: "5MT", value: "5MT" },
    { label: "6MT", value: "6MT" },
    { label: "6.5MT", value: "6.5MT" },
    { label: "7MT", value: "7MT" },
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
    { label: "10MT", value: "10MT" },
    { label: "11MT", value: "11MT" },
    { label: "12MT", value: "12MT" },
  ],
  truck24ft: [
    { label: "5MT", value: "5MT" },
    { label: "6MT", value: "6MT" },
    { label: "6.5MT", value: "6.5MT" },
    { label: "7MT", value: "7MT" },
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
    { label: "10MT", value: "10MT" },
    { label: "11MT", value: "11MT" },
    { label: "12MT", value: "12MT" },
  ],
  torus: [
    { label: "14MT", value: "14MT" },
    { label: "16MT", value: "16MT" },
    { label: "18MT", value: "18MT" },
    { label: "21MT", value: "21MT" },
    { label: "22MT", value: "22MT" },
    { label: "24MT", value: "24MT" },
    { label: "25MT", value: "25MT" },
    { label: "30MT", value: "30MT" },
    { label: "35MT", value: "35MT" },
  ],
  truck32ftsxl: [
    { label: "7MT", value: "7MT" },
    { label: "9MT", value: "9MT" },
    { label: "10MT", value: "10MT" },
  ],
  truck32ftmxl: [
    { label: "14MT", value: "15MT" },
    { label: "16MT", value: "16MT" },
    { label: "18MT", value: "18MT" },
  ],
  trailer20ft: [
    { label: "5MT", value: "5MT" },
    { label: "6MT", value: "6MT" },
    { label: "7MT", value: "7MT" },
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
    { label: "10MT", value: "10MT" },
    { label: "14MT", value: "14MT" },
    { label: "16MT", value: "16MT" },
    { label: "18MT", value: "18MT" },
    { label: "20MT", value: "20MT" },
  ],
  trailer40ft: [
    { label: "5MT", value: "5MT" },
    { label: "6MT", value: "6MT" },
    { label: "7MT", value: "7MT" },
    { label: "8MT", value: "8MT" },
    { label: "9MT", value: "9MT" },
    { label: "10MT", value: "10MT" },
    { label: "14MT", value: "14MT" },
    { label: "16MT", value: "16MT" },
    { label: "18MT", value: "18MT" },
    { label: "20MT", value: "20MT" },
    { label: "25MT", value: "25MT" },
    { label: "30MT", value: "30MT" },
    { label: "40MT", value: "40MT" },
  ],
};
// Vehicle size based on Vehicle types
export const bodytype = {
  normaltype: [
    { label: "Open Body", value: "Open Body" },
    { label: "Close Body", value: "Close Body" },
  ],
  trailer20ft: [
    { label: "Open Body", value: "Open Body" },
    { label: "Close Body", value: "Close Body" },

    { label: "Half Dala", value: "Half Dala" },
  ],
  trailer40ft: [
    { label: "Open Body", value: "Open Body" },
    { label: "Half Dala", value: "Half Dala" },
    { label: "Container", value: "Container" },
    { label: "Low Bed", value: "Low Bed" },
    { label: "Semi Bed", value: "Semi Bed" },
    { label: "Platform", value: "Platform" },
  ],
};
